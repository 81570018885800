import React from 'react'
import Layout from '../../components/Layout'

export default () => (
  <Layout>
    <section className="section">
      <div className="container">
        <div className="content" style={{marginTop: "70px"}}>
          <h1>Takk fyrir</h1>
          <p style={{marginBottom: "230px"}}>Skilaboðum var komið til skila</p>
        </div>
      </div>
    </section>
  </Layout>
)
